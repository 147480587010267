<template>
  <div class="contact-form">

    <div class="top">
      <div>
        <span>姓名：</span>
        <input
          v-model="form.name"
          type="text"
        >
      </div>
      <div>
        <span>手机号：</span>
        <input
          v-model="form.mobile"
          type="text"
        >
      </div>
      <div>
        <span>邮箱：</span>
        <input
          v-model="form.email"
          type="text"
        >
      </div>
    </div>
    <div class="textarea">
      <span>留言：</span>
      <textarea v-model="form.remark"></textarea>
    </div>
    <a
      class="btn"
      href="javascript:;"
      @click="submit"
    >提交</a>
  </div>
</template>

<script>
import { addContact } from '@/api'
export default {
  data() {
    return {
      form: {
        name: '',
        mobile: '',
        email: '',
        remark: '',
      },
    }
  },
  methods: {
    submit() {
      addContact(this.form)
        .then((res) => {
          if (res.data.code) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .contact-form {
    > .top {
      margin-top: 41px;
      display: flex;
      justify-content: space-between;
      > div {
        width: calc((100% - 60px) / 3);
        border: 1px solid #dddddd;
        font-size: 20px;
        line-height: 54px;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0 16px;
        color: #333;
        display: flex;
        font-family: PingFangSC-Regular, PingFang SC;
        > input {
          flex: 1;
        }
      }
    }
    > .textarea {
      margin-top: 34px;
      height: 180px;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      font-size: 20px;
      border-radius: 2px;
      padding: 14px 16px;
      color: #333;
      display: flex;
      font-family: PingFangSC-Regular, PingFang SC;
      > textarea {
        flex: 1;
      }
    }
    > .btn {
      text-align: center;
      margin-top: 34px;
      display: inline-block;
      width: 238px;
      line-height: 56px;
      background: #c8a475;
      border-radius: 2px;
      font-size: 20px;
      color: #ffffff;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    > .btn:hover {
      opacity: 0.7;
    }
  }
}
@media screen and (max-width: 750px) {
  .contact-form {
    > .top {
      margin-top: 14px;
      > div {
        border: 1px solid #dddddd;
        font-size: 14px;
        line-height: 42px;
        border-radius: 2px;
        padding: 0 15px;
        color: #333;
        display: flex;
        font-family: PingFangSC-Regular, PingFang SC;
        > input {
          flex: 1;
        }
        margin-top: 12px;
      }
    }
    > .textarea {
      margin-top: 12px;
      height: 140px;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      font-size: 14px;
      border-radius: 2px;
      padding: 13px 15px;
      color: #333;
      display: flex;
      font-family: PingFangSC-Regular, PingFang SC;
      > textarea {
        flex: 1;
      }
    }
    > .btn {
      text-align: center;
      margin-top: 22px;
      display: block;
      line-height: 44px;
      background: #c8a475;
      border-radius: 2px;
      font-size: 15px;
      color: #ffffff;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
</style>