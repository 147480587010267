<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img class="add" src="@/assets/address.png" />
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link to="/wealth">财富机会</router-link>
      </div>
    </div>
    <div class="wealth-wrap">
      <img class="jmyq" src="@/assets/jmyq.png" />
      <img class="jmyq-m" src="@/assets/jmyq_m.png" />
      <div class="jmys">
        <img class="title" src="@/assets/jmys_title.png" />
        <img class="pc" src="@/assets/jmys.png" />
        <img class="m" src="@/assets/jmys_m.png" />
      </div>
      <div class="slgy">
        <img class="title" src="@/assets/slgy_title.png" />
        <div class="con">
          <img src="@/assets/slgy_3.png" />
          <img src="@/assets/slgy_2.png" />
          <img src="@/assets/slgy_4.png" />
          <img src="@/assets/slgy_5.png" />
          <img src="@/assets/slgy_1.png" />
          <!-- <div>
            <img src="@/assets/slgy_6.png" />
            <img src="@/assets/slgy_7.png" />
            <img src="@/assets/slgy_8.png" />
          </div> -->
        </div>
      </div>
      <div class="jmlc">
        <img class="title" src="@/assets/jmlc.png" />
        <div class="step">
          <div>
            <span>01</span>
            <div>
              <p>申请考察</p>
              <span>提交加盟申请</span>
            </div>
          </div>
          <div>
            <span>02</span>
            <div>
              <p>分析选址</p>
              <span>市场分析<br />店面选址</span>
            </div>
          </div>
          <div>
            <span>03</span>
            <div>
              <p>装修验收</p>
              <span>店铺装修设计</span>
            </div>
          </div>
          <div>
            <span>04</span>
            <div>
              <p>培训选剑</p>
              <span>合作伙伴培训<br />销售商品挑选</span>
            </div>
          </div>
          <div>
            <span>05</span>
            <div>
              <p>开业准备</p>
              <span>店面开业预选</span>
            </div>
          </div>
          <div>
            <span>06</span>
            <div>
              <p>营销指导</p>
              <span>店面营销指导</span>
            </div>
          </div>
        </div>
        <div class="form">
          <contact-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactForm from "@/components/ContactForm";
export default {
  name: "Wealth",
  components: {
    contactForm,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .bread{
    // width: 1760px;
    margin: 0 auto;
  }
  .wrap {
    background: #f2f2f2;
    padding: 0 20px;
    > .wealth-wrap {
      background: #fff;
      > .jmyq {
        width: 100%;
      }
      .jmyq-m {
        display: none;
      }
      > .jmys {
        > .title {
          display: block;
          margin: 0 auto;
        }
        > .pc {
          width: 100%;
          margin-top: 52px;
        }
        > .m {
          display: none;
        }
      }
      > .slgy {
        margin-top: 21px;
        padding: 0 230px;
        > .title {
          display: block;
          margin: 0 auto;
          margin-bottom: 50px;
        }
        > .con {
          > img {
            margin-bottom: 78px;
          }
          > img:nth-last-child(1) {
            margin-bottom: 0;
          }
          > div {
            margin-top: 124px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
      > .jmlc {
        margin-top: 30px;
        padding: 0 80px 104px;
        > .title {
          display: block;
          margin: 0 auto;
        }

        > .form {
          margin-top: 84px;
          padding: 0 32px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .wealth-wrap {
      background: #fff;
      .jmyq-m {
        width: 100%;
      }
      > .jmyq {
        display: none;
      }
      > .jmys {
        > .title {
          display: block;
          width: 100%;
        }
        > .m {
          width: 100%;
          margin-top: 40px;
        }
        > .pc {
          display: none;
        }
      }
      > .slgy {
        margin-top: 13px;
        > .title {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }
        > .con {
          padding: 0 15px;
          > img {
            margin-bottom: 20px;
            max-width: 100%;
          }
          > img:nth-last-child(1) {
            margin-bottom: 0;
          }
          > div {
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            > img {
              width: calc((100% - 55px - 55px) / 3);
            }
          }
        }
      }
      > .jmlc {
        margin-top: 19px;
        padding: 0 15px 30px;
        > .title {
          display: block;
          width: 100%;
        }
        > .form {
          margin-top: 26px;
        }
      }
    }
  }
}
</style>